import "./App.css";
import myImage from "./Imagedd0058.png";

import { Image } from 'antd';
import React from 'react';
import { Route, Routes, useNavigate } from "react-router-dom";
import {  Row,  Col,  List,  Card} from 'antd';
import {LineChartOutlined} from '@ant-design/icons';  
/*
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      <img src={myImage} />
    </div>
*/ 
const App = () => {
  return (<div>
    

     
      



    <Row className="kontakt2"  
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
      
      <Col span={12} className="kontakt2" style={{ maxWidth: '500px',
        display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', padding: 4, margin: 0}}
      >
        <Card 
          className="kontakt2"
          bordered={true}
        >
            <img src={myImage} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} />
        </Card>
      </Col>

      <Col span={12} className="kontakt2" style={{ Height: '1000px', fontfamily: 'MojFont', textAlign: 'left', padding: 4, margin: 0}}>
        <Card  bordered={true} style={{ fontfamily: 'MojFont'}}>
<h1 className="kontakt2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Брка електро</h1>
<h1 className="kontakt2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Електроинсталатер</h1>
<h1 className="kontakt2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>065/90-50-70-4</h1>
<h1 className="kontakt2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Ср. Митровица - Шид</h1>
<h1 className="kontakt2" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Вајбер/Позив</h1>
        </Card>
      </Col>
    </Row>

    
      
      <Col className="kontakt1"    >
        <Row span={12} className="kontakt1" style={{ textAlign: 'right',  padding: 4, margin: 0}}>

    <Card 
      //title="slika" 
      className="kontakt1"
      bordered={true}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
    >
      <img src={myImage} style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'cover' }} />
      </Card>
      </Row>
        <Row span={12} className="kontakt1" style={{ textAlign: 'right',  padding: 4, margin: 0}}>



        <Card 
      //title="slika" 
      className="kontakt1"
      bordered={true}
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%' }}
    >
      <h1 className="kontakt1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Брка електро</h1>   
      <h1 className="kontakt1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Електроинсталатер</h1>
      <h1 className="kontakt1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>065/90-50-70-4</h1>
      <h1 className="kontakt1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Ср. Митровица - Шид</h1>
      <h1 className="kontakt1" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>Вајбер/Позив</h1>
    </Card>
    
    </Row>
      </Col>




    </div>
  );
};
export default App;